import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import HomePageHeader from '../../components/Marketing/HomePageHeader/HomePageHeader';
import PageHeadline from '../../components/Layout/PageHeadline/PageHeadline';
import CustomerTestimonials from '../../components/Marketing/CustomerTestimonials/CustomerTestimonials';
import InstagramFeed from '../../components/Marketing/InstagramFeed/InstagramFeed';
import styles from './Homepage.module.scss';
import LargeDescription from '../../components/Marketing/LargeDescription/LargeDescription';
import Container from '../../components/Base/Container/Container';

import SectionTitle from '../../components/Marketing/SectionTitle/SectionTitle';
import FeatureGrid from '../../components/Marketing/FeatureGrid/FeatureGrid';
import ShortDescription from '../../components/Marketing/ShortDescription/ShortDescription';
import Masonry from '../../components/Marketing/Masonry/Masonry';
import { WEB_IMGIX } from '../../utils/variables';
import IntegrationsOverview from '../../components/Marketing/IntegrationsOverview/IntegrationsOverview';
import { trackEvent } from '../../utils/trackingParty';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../components/Messages/HomepageMessages';
import Head from 'next/head';
import NavbarContainer from '../Common/NavbarContainer/NavbarContainer';
import FooterContainer from '../Common/FooterContainer';
import FullWidthBannerSignup from '../../components/Marketing/BannerFullWidth/FullWidthBannerSignup';
import CategoriesSliderSkeleton from '../../components/Marketing/CategoriesSlider/Skeleton';

const BlogFeed = dynamic(() => import('../../components/Marketing/BlogFeed/BlogFeed'), {
    ssr: false
});
const CategoriesSlider = dynamic(
    () => import('../../components/Marketing/CategoriesSlider/CategoriesSlider'),
    {
        ssr: false,
        loading: () => <CategoriesSliderSkeleton />
    }
);

const HomePage = (props) => {
    const video = useRef(null);
    const videoPlaying = useRef(false);

    const { intl } = props;
    const user = useSelector((state) => state.auth.user);
    const isUserLoaded = useSelector((state) => state.auth.isUserLoaded);
    const { homepageData, homepageMockups } = useSelector((state) => state.server);

    const handleVideoClick = () => {
        if (!video.current) return;

        if (videoPlaying.current) {
            video.current.pause();
            videoPlaying.current = false;
        } else {
            const playPromise = video.current.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {})
                    .catch((error) => {
                        console.log(error);
                    });
            }
            videoPlaying.current = true;
            trackEvent('Homepage video play');
        }
    };

    // const dpr = isClient() ? Math.min(window.devicePixelRatio, 2) : 1;

    return (
        <>
            <Head>
                <title>{intl.formatMessage(messages.pageTitle)}</title>
                <meta name="description" content={intl.formatMessage(messages.metaDescription)} />
                <meta property="og:type" content="website" />
                <meta property="fb:app_id" content="172780239415226" />
                <meta property="og:title" content={intl.formatMessage(messages.pageTitle)} />
                <meta
                    property="og:description"
                    content={intl.formatMessage(messages.metaDescription)}
                />
                <meta property="og:url" content="https://smartmockups.com" />
                <meta
                    property="og:image"
                    content="https://smartmockups.com/android-chrome-400x400.jpg"
                />
                <meta
                    property="og:image:secure_url"
                    content="https://smartmockups.com/android-chrome-400x400.jpg"
                />
                <meta property="og:image:type" content="image/jpeg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@smartmockups" />
                <meta
                    property="twitter:description"
                    content={intl.formatMessage(messages.metaDescription)}
                />
                <meta
                    property="twitter:image"
                    content="https://smartmockups.com/android-chrome-400x400.jpg"
                />
                <meta httpEquiv="Accept-CH" content="DPR,Width,Viewport-Width" />
            </Head>

            <NavbarContainer />

            <HomePageHeader mockups={homepageMockups}/>

            <Container>
                <div className="m-t-lg m-b-md " />
            </Container>

            <Container size="lg">
                <div className={styles.rowVideo}>
                    <div className={styles.absoluteHalf}>
                        <video
                            ref={video}
                            className={styles.introVideo}
                            controls
                            preload="none"
                            poster={`${WEB_IMGIX}/intro-video-placeholder.jpg?w=892&dpr=2&auto=compress`}
                            width={892}
                            height={558}
                            onClick={handleVideoClick}
                        >
                            <source
                                src="https://s3.amazonaws.com/smartmockups-web-assets/introduction.webm"
                                type="video/webm"
                            />
                            <source
                                src="https://s3.amazonaws.com/smartmockups-web-assets/introduction.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <Container>
                        <div className={styles.rowNoMargin}>
                            <div className={styles.half}>
                                <span />
                            </div>
                            <div className={styles.contentRight}>
                                <LargeDescription
                                    description={intl.formatMessage(messages.introDescription)}
                                    list={[
                                        intl.formatMessage(messages.introList1),
                                        intl.formatMessage(messages.introList2),
                                        intl.formatMessage(messages.introList3)
                                    ]}
                                    subtitle={intl.formatMessage(messages.introSubtitle)}
                                    title={intl.formatMessage(messages.introTitle)}
                                />
                            </div>
                        </div>
                    </Container>
                </div>
            </Container>
            <Container className="m-b-lg">
                <CategoriesSlider />
            </Container>
            <Container size="lg">
                <div className={styles.rowBlue}>
                    <div className={styles.absoluteHalfOffset}>
                        <Masonry
                            items={[
                                {
                                    src: 'masonry-features-url-picker.jpg',
                                    width: 261,
                                    height: 255,
                                    isShadow: true
                                },
                                {
                                    src: 'masonry-features-picker.jpg',
                                    width: 261,
                                    height: 498
                                },
                                {
                                    src: 'masonry-features-upload-new-2.jpg',
                                    width: 261,
                                    height: 233,
                                    isShadow: true
                                },
                                {
                                    src: 'masonry-features-crop.jpg',
                                    width: 261,
                                    height: 321
                                },
                                {
                                    src: 'masonry-features-picker-quality.jpg',
                                    width: 261,
                                    height: 194,
                                    isShadow: true
                                },
                                {
                                    src: 'masonry-features-bg.jpg',
                                    width: 261,
                                    height: 371
                                }
                            ]}
                        />
                    </div>

                    <Container isPaddingDisabled={false}>
                        <div className={styles.rowNoMargin}>
                            <div className={styles.half}>
                                <span />
                            </div>
                            <div className={styles.contentRight}>
                                <FeatureGrid singleColumn>
                                    <SectionTitle
                                        title={intl.formatMessage(messages.featuresTitle)}
                                        subtitle={intl.formatMessage(messages.featuresSubtitle)}
                                        design="blue"
                                    />
                                    <ShortDescription
                                        design="blue"
                                        title={intl.formatMessage(messages.uploadTitle)}
                                        description={intl.formatMessage(messages.uploadDescription)}
                                    />
                                    <ShortDescription
                                        design="blue"
                                        title={intl.formatMessage(messages.customizeTitle)}
                                        description={intl.formatMessage(
                                            messages.customizeDescription
                                        )}
                                    />
                                    <ShortDescription
                                        design="blue"
                                        title={intl.formatMessage(messages.exportTitle)}
                                        description={intl.formatMessage(messages.exportDescription)}
                                    />
                                </FeatureGrid>
                            </div>
                        </div>
                    </Container>
                </div>
            </Container>

            <Container>
                <IntegrationsOverview
                    title={intl.formatMessage(messages.integrationsTitle)}
                    subtitle={intl.formatMessage(messages.integrationsSubtitle)}
                />

                <div className="m-b-lg">
                    <InstagramFeed instagram={homepageData.instagram} />
                </div>

                <div className="m-b-lg">
                    <PageHeadline
                        title={
                            <FormattedMessage
                                defaultMessage="Trusted by thousands of people {newLine} and companies worldwide"
                                id="global.testimonials.title"
                                values={{ newLine: <br /> }}
                            />
                        }
                        subtitle={
                            <FormattedMessage
                                defaultMessage="Testimonials"
                                id="global.testimonials.subtitle"
                            />
                        }
                    />
                    <CustomerTestimonials />
                </div>

                <div className="m-b-lg">
                    <BlogFeed blog={homepageData.blog} />
                </div>
                {props.children}
            </Container>
            {!user && isUserLoaded && <FullWidthBannerSignup />}
            <FooterContainer />
        </>
    );
};

export default injectIntl(HomePage);
