import React from 'react';
import styles from './ModalSplitContent.module.scss';
import PropTypes from 'prop-types';

const ModalSplitContent = props => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>{props.imageComponent}</div>
            <div className={styles.right}>{props.children}</div>
        </div>
    );
};

export default ModalSplitContent;

ModalSplitContent.propTypes = {
    children: PropTypes.any.isRequired,
    imageComponent: PropTypes.node.isRequired
};
