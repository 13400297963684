import React from 'react';
import PropTypes from 'prop-types';
import styles from './IntegrationsOverview.module.scss';
import IntegrationsIcons from './Integrations';
import Headline from '../../Layout/Headline/Headline';

const IntegrationsOverview = props => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <Headline title={props.title} subtitle={props.subtitle} />
            </div>
            <div className={styles.iconWrapper}>
                <IntegrationsIcons className={styles.icon} width={480} height={120} />
            </div>
        </div>
    );
};

export default IntegrationsOverview;

IntegrationsOverview.propTypes = {
    subtitle: PropTypes.string,
    title: PropTypes.string
};
