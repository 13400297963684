import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import styles from './HelpscoutButton.module.scss';
import HelpscoutInit from './HelpscoutInit';
import PropTypes from 'prop-types';
import * as helpScoutActions from '../../../actions/windowActions';
import useActions from '../../../hooks/useActions';
import { isClient } from '../../../utils/envDetection';

const HelpscoutButton = (props) => {
    const { children, isDefault, className, onClick, navigate } = props;

    const { authenticated } = useSelector((state) => state.auth);
    const { setActiveHelpscout } = useActions(helpScoutActions);
    const { isActive } = useSelector((state) => state.helpscout);
    const checkIsActive = isActive || authenticated || (isClient() && window.Beacon);

    const [isLocActive, setLocIsActive] = useState(false);

    useEffect(() => {
        if (isLocActive) {
            openBeacon();
        }
    }, [isLocActive]);

    const openBeacon = () => {
        window.Beacon('open');
        if (navigate) {
            window.Beacon('navigate', navigate);
        }
    };

    const handleClick = () => {
        if (window.Beacon) {
            openBeacon();
        } else {
            setLocIsActive(true);
            setActiveHelpscout(true);
        }
    };

    const getClassName = () => {
        if (className) {
            return className;
        }

        if (isDefault) {
            return `font-medium ${styles.button} ${checkIsActive ? styles.active : null}`;
        }

        return null;
    };

    const handleOnClick = () => {
        onClick?.();
    };

    return (
        <>
            <button
                className={getClassName()}
                onClick={() => {
                    handleClick();
                    handleOnClick();
                }}
            >
                {children}
            </button>

            <Head>{HelpscoutInit(checkIsActive)}</Head>
        </>
    );
};

HelpscoutButton.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    isDefault: PropTypes.bool,
    className: PropTypes.string,
    navigate: PropTypes.string
};

export default HelpscoutButton;
