import Pica from 'pica/dist/pica';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useActions from './useActions';
import * as mockupListActions from '../actions/mockupList';
import { uploadUserFileToS3 } from '../apis/userFileApi';
import { TEMP_UPLOADS_S3 } from '../utils/variables';
import { loadBlobAsImg } from '../utils/loadBlob';
import { createCanvas } from '@smartmockups/rendering';
import { trackEvent } from '../utils/trackingParty';
import loadImageAsCanvas from "../utils/loadImageAsCanvas";

export const sourceHistory = 'history';
export const sourceTemp = 'temp';

const maxLength = 500;

const useLivePreview = (trigger) => {
    const { setLivePreview } = useActions(mockupListActions, 'setLivePreview');
    const router = useRouter();
    let { isUploading, isClientSide, file, fileId, source, origin, canvas, predefinedFileId } =
        useSelector((state) => state.mockupsList.livePreview) || {};
    const user = useSelector((state) => state.auth.user);

    if (router.query.preview !== undefined && router.query.preview !== fileId) {
        fileId = router.query.preview;
        source = sourceHistory;
        setLivePreview({ fileId, source });
    }

    // detect an origin page of live preview event
    function getCurrentOrigin() {
        let origin;
        const rootPath = router.route.split('/')[1];

        if (rootPath === 'mockups') {
            origin = {
                path: 'collection',
                query: router.query.slug?.[0]
            };
        }
        if (rootPath === 'user-collection') {
            origin = {
                path: 'userCollection',
                query: router.query.id ?? null
            };
        }
        if (router.route === "/") {
            origin = {
                path: '/'
            };
        }

        return origin;
    }


    const uploadFile = async (blob) => {
        file = blob;
        isUploading = true;
        isClientSide = false;
        canvas = null;
        let url;

        setLivePreview({ isUploading, file, isClientSide, canvas });

        const filetype = getFileType(file);
        const resizedBlob = await resize(file);
        try {
            url = await uploadUserFileToS3(resizedBlob, filetype, user.username)
        } catch(err) {
            alert(`Error: ${err.status} - ${err.statusText}`)
            console.error(err)
            setLivePreview(undefined);
        }
        if (!url) return;
        const uploadedFileKey = url.slice(TEMP_UPLOADS_S3.length + 1);
        source = sourceTemp;
        isUploading = false;
        origin = getCurrentOrigin();

        setLivePreview({ isUploading, file, fileId: uploadedFileKey, source, origin });
        trackLivePreviewEvent('Live Preview uploaded', { isEdit: !!fileId });
    };

    const uploadFileClient = async (url, id) => {
        isUploading = true;
        isClientSide = true;
        predefinedFileId = id;
        setLivePreview({ isUploading, isClientSide, predefinedFileId });
        canvas = await loadImageAsCanvas(url);
        isUploading = false;
        origin = getCurrentOrigin();
        setLivePreview({ isUploading, canvas, origin});
    };

    const trackLivePreviewEvent = (eventType, params) => {
        const payload = { origin, trigger, ...params };
        trackEvent(eventType, payload);
    };

    const resize = async (file) => {
        const img = await loadBlobAsImg(file);
        const isLandscape = img.width > img.height;
        let toWidth;
        let toHeight;
        if (isLandscape && img.width >= maxLength) {
            toWidth = maxLength;
            toHeight = img.height * (toWidth / img.width);
        } else if (!isLandscape && img.height >= maxLength) {
            toHeight = maxLength;
            toWidth = img.width * (toHeight / img.height);
        } else {
            return file;
        }
        const resizedCanvas = createCanvas(toWidth, toHeight);
        const pica = new Pica();
        const resizeOption = {
            toWidth,
            toHeight,
            unsharpAmount: 90,
            unsharpRadius: 0.6,
            unsharpThreshold: 2,
            alpha: true
        };
        return pica
            .resize(img, resizedCanvas, resizeOption)
            .then((result) => pica.toBlob(result, file.type));
    };

    const clear = () => {
        const currentPage = getCurrentOrigin();
        const isCurrentPage =
            currentPage.path === origin.path && currentPage.query === origin.query;
        trackLivePreviewEvent('Live Preview cleared', { isCurrentPage });
        setLivePreview(undefined);
    };

    return {
        file,
        fileId,
        source,
        canvas,
        predefinedFileId,
        isUploading,
        isClientSide,
        uploadFile,
        uploadFileClient,
        clear
    };
};

// Idea for improvement: extend this to primarilly use mimetype (File type has it, resized Blob as well).
// And move this to /utils for use in other places.
const getFileType = (file) => file.name.split('.').pop();

export default useLivePreview;
