import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import NavSection from './NavSection';
import styles from './Nav.module.scss';
import { FormattedMessage } from 'react-intl';

const Nav = (props) => {
    function renderNavSections() {
        return props.menuItems.map((category) => {
            return <NavSection category={category} key={`navitem${category.slug}`} />;
        });
    }

    return (
        <ul className={styles.navbar}>
            <li className={styles.navItem}>
                <Link
                    to="/mockups"
                    activeClassName={styles.navLinkActive}
                    className={styles.navLink}
                    tabIndex={0}
                >
                    <FormattedMessage defaultMessage="All mockups" />
                </Link>
            </li>
            <li className={styles.navItem}>
                <Link
                    to={
                        !props.isUserWithoutAccount
                            ? '/custom-mockups'
                            : '/custom-mockups/introduction'
                    }
                    activeClassName={styles.navLinkActive}
                    className={styles.navLink}
                    tabIndex={0}
                >
                    <FormattedMessage
                        defaultMessage="Custom"
                        id="global.nav.label.customMockupsShort"
                    />
                </Link>
            </li>

            {renderNavSections()}
        </ul>
    );
};

Nav.propTypes = {
    menuItems: PropTypes.array,
    isUserWithoutAccount: PropTypes.bool
};

export default Nav;
