import React from 'react';
import GradientBgBlock from '../../Base/GradientBgBlock/GradientBgBlock';
import { FormattedMessage } from 'react-intl';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import Headline from '../../Layout/Headline/Headline';
import styles from './BannerFullWidth.module.scss';
import Button from '../../Base/Button/Button';
import Container from '../../Base/Container/Container';
import Emoji from '../../Base/Emoji/Emoji';

const FullWidthBannerSignup = () => (
    <Container size="lg">
        <GradientBgBlock>
            <div className={styles.content}>
                <Headline
                    design="inverse"
                    title={
                        <FormattedMessage
                            defaultMessage="Get started"
                            id="global.nav.label.signupShort"
                        />
                    }
                    subtitle={
                        <FormattedMessage
                            defaultMessage="Join thousands of professionals and {newline}start your 7-day free trial"
                            values={{ newline: <br /> }}
                        />
                    }
                />

                <Link to="/signup">
                    <Button size="xlarge" design="white" className={styles.btn}>
                        <Emoji symbol="👉" />{' '}
                        <FormattedMessage
                            defaultMessage="Get started for free"
                            id="global.nav.label.signupLong"
                        />
                    </Button>
                </Link>
                <div className="color-blue-light text-small">
                    <FormattedMessage
                        defaultMessage="*No credit card required, and you can cancel at any time. You can use it
                            free forever or upgrade to our subscription."
                    />
                </div>
            </div>
        </GradientBgBlock>
    </Container>
);

export default FullWidthBannerSignup;
