import React from 'react';
import PropTypes from 'prop-types';
import Headline from '../Headline/Headline';
import styles from './PageHeadline.module.scss';

const PageHeadline = props => (
    <div className={styles.wrapper}>
        <Headline title={props.subtitle} subtitle={props.title} design={props.design} />
    </div>
);

PageHeadline.propTypes = {
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node.isRequired,
    design: PropTypes.oneOf(['default', 'inverse', 'blue'])
};

export default PageHeadline;
