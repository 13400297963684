import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../../../components/Layout/Navbar/Navbar';
import AlertsContainer from '../Alerts/AlertsContainer';
import { getIdentifyData, showBeaconMessage } from '../../../utils/helpscoutBeaconHelpers';
import { isServer } from '../../../utils/envDetection';
import Script from 'next/script';
import { logout, clear } from '../../../actions/authActions';
import { useRouter } from 'next/router';
import HelpscoutButton from '../../../components/Marketing/Helpscout/HelpscoutButton';
import { DEFAULT_LOCALE } from '../../../utils/languageHelpers';
import { FormattedMessage } from 'react-intl';
import useActions from "../../../hooks/useActions";
import * as windowActions from '../../../actions/windowActions';
import useMenu from "../../../hooks/useMenu";
import BfBanner from "../../../components/Marketing/BfBanner/BfBanner";
import PropTypes from "prop-types";
import Head from "next/head";
import TrustpilotScript from "../../../components/Marketing/Trustpilot/TrustpilotScript";

const NavbarContainer = (props) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const { menuItems } = useMenu();

    const { user, isUserWithoutAccount, isUserLoaded, initialFederatedSignIn } = useSelector(
        (state) => state.auth
    );
    const { setTotalCountPaddle, setPaddleEventCallback, setPremiumModal } = useActions(windowActions);

    useEffect(() => {
        // if new user is loaded (usually from local storage)
        // load notifications
        addUserToBeacon(user);
        showBeaconMessage({
            messageId: '54dcf2cd-7a05-4c3b-8437-c7d408d5e7c8',
            user,
            targetPlan: 'Premium',
            targetFrequency: 'monthly',
            maxLength: 0.5,
            maxLenght: 1.5
        });
    }, [user, dispatch]);

    function addUserToBeacon(user) {
        if (isServer() || !user || !window.Beacon) return;

        const data = getIdentifyData(user);
        try {
            window.Beacon('identify', data);
            window.Beacon('prefill', {
                subject: 'Message from Smartmockups.com'
            });
        } catch (err) {
            console.log(err);
        }
    }

    const handleScriptError = () => {
        console.log('Unable to load payment script');
    };

    const handleScriptLoad = () => {
        /* eslint-disable no-undef */
        Paddle.Setup({
            vendor: 19214,
            eventCallback(eventData) {
                updatePrices(eventData);
                updateEventCallback(eventData);
            }
        });
        /* eslint-enable no-undef */
    };

    function updatePrices(data) {
        setTotalCountPaddle(data.eventData.checkout.prices.customer);
    }

    function updateEventCallback(data) {
        setPaddleEventCallback(data.event);
    }

    const handleLogoutClick = async (e) => {
        e.preventDefault();

        await dispatch(logout());
        await dispatch(clear());
        await router.push('/', '/', { locale: DEFAULT_LOCALE });
    };

    const handleUpgradeClick = () => {
        dispatch(setPremiumModal('upgrade', 'Navbar upgrade button'));
    };

    const handleUpgradeTrialClick = () => {
        dispatch(setPremiumModal('upgrade', 'Profile Dropdown trial button'));
    };

    function renderScripts() {
        if (isServer()) return;

        /* eslint-disable react/no-unescaped-entities */
        return (
            <>
                <Script
                    src="https://cdn.paddle.com/paddle/paddle.js"
                    strategy="afterInteractive"
                    onError={handleScriptError}
                    onLoad={handleScriptLoad}
                />
                <TrustpilotScript/>
            </>
        );
    }

    return (
        <header>
            <Head>
                <meta name="viewport" content="width=device-width,minimum-scale=1,maximum-scale=1,user-scalable=no" />
            </Head>
            <AlertsContainer />
            <BfBanner isHidden={props.isBannerHidden}/>
            <Navbar
                user={user}
                initialFederatedSignIn={initialFederatedSignIn}
                isUserWithoutAccount={isUserWithoutAccount}
                isUserLoaded={isUserLoaded}
                menuItems={menuItems}
                onLogoutClick={handleLogoutClick}
                onUpgradeClick={handleUpgradeClick}
                onUpgradeTrialClick={handleUpgradeTrialClick}
            />
            <HelpscoutButton isDefault>
                <FormattedMessage defaultMessage="Need help?" />
            </HelpscoutButton>
            {renderScripts()}
        </header>
    );
};

NavbarContainer.propTypes = {
    isBannerHidden: PropTypes.bool
}

export default NavbarContainer;
