import React from 'react';
import PropTypes from 'prop-types';
import styles from './UpgradeBtn.module.scss';
import { defineMessages, useIntl } from 'react-intl';
import Button from '../../../Base/Button/Button';
import UpgradeProminentIcon from './UpgradeProminentIcon.svg';

const UpgradeBtn = (props) => {
    const intl = useIntl();
    const messages = defineMessages({
        text: { defaultMessage: 'Upgrade to Pro' }
    });

    const defaultText = intl.formatMessage(messages.text);

    return (
        <Button onClick={props.onClick} design="text" size="small" className={styles.upgradeBtn}>
            <img className={props.imgClassName} src={UpgradeProminentIcon} alt="" />
            <span className={styles.upgradeLink}>{defaultText}</span>
        </Button>
    );
};
UpgradeBtn.propTypes = {
    imgClassName: PropTypes.string,
    onClick: PropTypes.func
};

export default UpgradeBtn;
