import React, {useRef, useEffect} from 'react';
import PropTypes from "prop-types";

const TrustpilotWidget = (props) => {
    const { type } = props;
    const ref = useRef(null);

    const widgetType = {
        microCombo: {
            id: "5419b6ffb0d04a076446a9af",
            height: "20px"
        },
        horizontal: {
            id: "5406e65db0d04a09e042d5fc",
            height: "28px"
        },
        carousel: {
            id: "53aa8912dec7e10d38f59f36",
            height: "140px"
        }
    };

    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return (
        <>
            <div ref={ref} className="trustpilot-widget" data-locale="en-US" data-template-id={widgetType[type].id}
                 data-businessunit-id="5def7326490bc20001d76883" data-style-height={widgetType[type].height} data-style-width="100%"
                 data-theme="light" data-tags="SelectedReview">
                <a href="https://www.trustpilot.com/review/smartmockups.com" target="_blank" rel="noreferrer">Trustpilot</a>
            </div>
        </>
    );
};

TrustpilotWidget.propTypes = {
    type: PropTypes.string.isRequired
};

export default TrustpilotWidget;
