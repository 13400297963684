import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { pushBrowserHistoryLocalised } from '../utils/languageHelpers';
import { useRouter } from 'next/router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { trackEvent } from '../utils/trackingParty';
import useQueryUrl from './useQueryUrl';
import { filterMockups, orderMockups } from '../utils/mockupListFiltering';
import { isClient } from '../utils/envDetection';
import Sockette from 'sockette';
import { BULK_EXPORT_API_WS } from '../utils/variables';
import { getUserToken } from '../apis/helpers';
import useLivePreview from './useLivePreview';
import saveFile from '../utils/saveFile';
import useActions from './useActions';
import * as windowActions from '../actions/windowActions';
import * as mockupList from '../actions/mockupList';
import * as userCollectionsAction from '../actions/userCollectionsAction';
import useMenu from "./useMenu";

const useUserCollections = (props) => {
    const { user } = useSelector((state) => state.auth);
    const { menuItems } = useMenu();
    const { categories } = useSelector((state) => state.customMockups);
    const { order } = useSelector((state) => state.mockupsList);
    const {
        userCollections,
        userCollection,
        hasLoadedCollections,
        hasLoadedCollectedMockups,
        hasCollectionChange,
        hasChangedStatus
    } = useSelector((state) => state.userCollections);
    const livePreview = useLivePreview();

    const router = useRouter();
    const { query } = router;
    const timestamp = parseInt(query.id);
    const intl = useIntl();
    const { makeQueryUrl } = useQueryUrl();
    const {
        setAlert,
        setPremiumModal,
        setOrder,
        addUserCollection,
        removeUserCollection,
        editUserCollection,
        updateMockupsCollection,
        fetchUserCollectionMockups,
        fetchUserCollections
    } = useActions({ ...windowActions, ...mockupList, ...userCollectionsAction }, [
        'setAlert',
        'setPremiumModal',
        'setOrder',
        'addUserCollection',
        'removeUserCollection',
        'editUserCollection',
        'updateMockupsCollection',
        'fetchUserCollectionMockups',
        'fetchUserCollections'
    ]);

    const maxCountCollections = 12;
    const maxCountMockups = 100;
    const collectedMockups = isClient() ? localStorage.getItem('collectedMockups') : null;

    const initialState = {
        newCollection: '',
        modalOpen: false,
        modalTitle: '',
        modalType: '',
        success: false,
        isExportLoading: false
    };

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    );

    /// ///////////
    // Handlers
    /// //////////
    function roundPercent(value) {
        return Math.floor(value * 100);
    }
    const messages = defineMessages({
        alertExport: { defaultMessage: 'Exporting your collection' },
        alertLink: { defaultMessage: '👍' },
        alertLinkLabel: { defaultMessage: 'Your download package is ready' },
        exportUserCollection: { defaultMessage: 'User collection' },
        exportLoadedMockups: { defaultMessage: 'Loaded mockups' },
        exportRenderingMockups: { defaultMessage: 'Rendering mockups' },
        exportPreparingZIPfile: { defaultMessage: 'Preparing ZIP file' },
        exportSavingZIPfile: { defaultMessage: 'Saving ZIP file' },
        alertError: { defaultMessage: 'Oops, something went wrong' }
    });

    const handleBulkExport = async () => {
        const data = {
            timestamp,
            username: user.username,
            fileId: livePreview.fileId,
            size: 'high', // TODO this shall be handled by dropdown button to choose the size
            color: null,
            source: livePreview.source
        };

        trackEvent('UserCollection download triggered', { data });

        const token = await getUserToken();
        return new Promise((resolve, reject) => {
            setState({ isExportLoading: true });

            setAlert(data.filename, {
                type: 'loader',
                label: intl.formatMessage(messages.alertExport),
                progress: 0
            });

            const ws = new Sockette(`${BULK_EXPORT_API_WS}?Authorization=${token}`, {
                timeout: 2000,
                maxAttempts: 2,
                onmessage(e) {
                    handleWSMessage(e);
                },
                onopen: () => {
                    handleOpen();
                },
                onerror: () => {
                    handleError();
                }
            });

            // event handlers
            function handleOpen() {
                console.log('Connection opened');
                ws.json(data);
            }
            function handleWSMessage(e) {
                console.log('Received:', e);
                const res = JSON.parse(e.data);
                // progress status
                if (res.hasOwnProperty('progress')) {
                    handleProgress(res);
                }
                // final response
                if (res.result) {
                    handleResult(res);
                }
                // error happened
                if (res.message === 'Internal server error') {
                    handleError(res);
                }
            }
            function handleProgress(res) {
                console.warn('progress', res);
                setAlert(data.filename, {
                    type: 'loader',
                    label: intl.formatMessage(messages[res.message]),
                    progress: roundPercent(res.progress / 100)
                });
            }
            function handleError() {
                setAlert(data.filename, {
                    type: 'link',
                    label: intl.formatMessage(messages.alertError),
                    linkLabel: '',
                    link: '#'
                });
                console.log('Connection closed');
                reject(ws);
                setState({ isExportLoading: false });
            }
            function handleResult(res) {
                setAlert(data.filename, {
                    type: 'link',
                    label: intl.formatMessage(messages.alertLink),
                    linkLabel: intl.formatMessage(messages.alertLinkLabel),
                    link: res.result.fileUrl
                });

                saveFile(res.result.fileUrl);

                setTimeout(() => {
                    setAlert(data.filename, null);
                }, 3000);

                resolve(res);
                ws.close();
                console.log('Connection closed');
                setState({ isExportLoading: false });

                trackEvent('UserCollection downloaded', {
                    fileKey: res.result.key,
                    mockupsCount: res.result.mockupsCount
                });
            }
        });
    };

    const handleOrderChange = (e) => {
        setOrder(e.currentTarget.value);
        trackEvent('Collection list order change', {
            order: e.currentTarget.value
        });
    };

    const handleFiltersChange = (e) => {
        trackEvent('Collection list filter change', {
            tags: e.currentTarget.value
        });
    };

    const handleCollectionUpdate = async (newCollection) => {
        try {
            const response = await editUserCollection(user.sharedDataUsername, timestamp, {
                title: newCollection
            });

            setState({
                success: !(response === true)
            });
        } catch (err) {
            setState({
                success: false
            });
        }

        return false;
    };

    const handleCollectionCreate = async (newCollection) => {
        try {
            const response = await addUserCollection(user.sharedDataUsername, {
                title: newCollection
            });

            trackEvent('Collection create');

            setState({
                success: !(response === true)
            });
        } catch (err) {
            setState({
                success: false
            });
        }

        return false;
    };

    const handleRemoveCollection = (e) => {
        e.preventDefault();
        removeUserCollection(user.sharedDataUsername, timestamp);
        trackEvent('Collection remove');
        pushBrowserHistoryLocalised('/user-collections');
    };

    const handleEmptyCollection = (e) => {
        e.preventDefault();
        pushBrowserHistoryLocalised('/mockups');
    };

    const handleUpdateMockups = (e) => {
        e.preventDefault();
        const timestampCollection = parseInt(e.currentTarget.id);
        const index = userCollections.findIndex(
            ({ timestamp }) => timestamp === timestampCollection
        );
        const findMockup = userCollections[index].mockups.findIndex(
            (shortId) => shortId === props.mockupId
        );
        const isFound = findMockup >= 0 ? 'REMOVE' : 'ADD';

        updateMockupsCollection(user.sharedDataUsername, timestampCollection, {
            type: isFound,
            mockup: props.mockupId
        });

        trackEvent(`Mockup ${isFound} collection `, {
            mockup: props.mockupId,
            collection: timestampCollection
        });
    };

    const handleModalClose = () => {
        if (hasChangedStatus === false) {
            setState({
                modalOpen: false
            });
        }
    };

    const handleModalCollectionCreate = (e) => {
        e.preventDefault();
        setState({
            modalOpen: true,
            modalType: 'create',
            modalTitle: 'Create new collection',
            success: ''
        });
    };

    const handleModalCollectionRename = (e) => {
        e.preventDefault();
        setState({
            modalOpen: true,
            modalType: 'rename',
            modalTitle: 'Rename collection',
            success: ''
        });
    };

    const handleModalCollectionRemove = (e) => {
        e.preventDefault();
        setState({
            modalOpen: true,
            modalType: 'remove',
            modalTitle: 'Remove collection'
        });
    };

    const handleModalCollectionUpdate = (e) => {
        e.preventDefault();
        if (user === null) {
            setPremiumModal('signup', 'Favorite modal');
            return;
        }

        setState({
            modalOpen: true,
            modalType: 'update',
            modalTitle: 'Add to collection'
        });
    };

    const handleLockedClick = (id) => {
        const modalType = user ? 'locked-mockup' : 'signup';
        setPremiumModal(`${modalType}:${id}`, 'Locked mockup latest modal');
    };

    const handleLockedExportClick = () => {
        const modalType = 'locked-feature';
        setPremiumModal(`${modalType}`, 'Locked feature UserCollection Export');
    };

    /// ////////////////
    // Helper functions
    /// ////////////////

    function buildFilterUrl(tags) {
        return makeQueryUrl(tags, 'filter');
    }

    function getFilteredMockups(mockups) {
        // get mockups for current filter
        const activeFilter = query.filter ? query.filter.split(',') : [];
        let filteredMockups = filterMockups(mockups, activeFilter);

        const showFreeFirst = !user || !user.isPremium;

        filteredMockups = orderMockups(filteredMockups, order, showFreeFirst);

        return filteredMockups;
    }

    function getFiltersOptions() {
        const options = [
            [
                {
                    title: <FormattedMessage defaultMessage="Photo" id="global.filter.photo" />,
                    slug: 'photo'
                },
                {
                    title: (
                        <FormattedMessage defaultMessage="Isolated" id="global.filter.isolated" />
                    ),
                    slug: 'isolated'
                }
            ],
            [
                {
                    title: <FormattedMessage defaultMessage="Free" id="global.filter.free" />,
                    slug: 'free'
                },
                {
                    title: <FormattedMessage defaultMessage="Premium" id="global.filter.premium" />,
                    slug: 'premium'
                }
            ]
        ];
        // premium user can't see the premium / free switch
        if (user && !user.isTrial && user.isPremium) {
            options.pop();
        } else if (user && user.isTrial && user.disableTrialWatermark) {
            options.pop();
        }

        return options;
    }

    return {
        state,
        user,
        query,
        intl,
        menuItems,
        order,
        categories,
        userCollections,
        userCollection,
        hasLoadedCollections,
        hasLoadedCollectedMockups,
        hasCollectionChange,
        hasChangedStatus,
        timestamp,
        maxCountCollections,
        maxCountMockups,
        collectedMockups,
        handleModalCollectionRename,
        handleModalCollectionRemove,
        handleModalCollectionUpdate,
        handleModalCollectionCreate,
        handleModalClose,
        handleCollectionCreate,
        handleCollectionUpdate,
        handleRemoveCollection,
        handleEmptyCollection,
        handleUpdateMockups,
        handleLockedClick,
        handleOrderChange,
        handleFiltersChange,
        buildFilterUrl,
        getFilteredMockups,
        getFiltersOptions,
        handleBulkExport,
        handleLockedExportClick,
        fetchUserCollectionMockups,
        fetchUserCollections
    };
};

export default useUserCollections;
