import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './CustomerTestimonials.module.scss';
import MoMosby from './images/MoMosby.jpg';
import MoMosby2x from './images/MoMosby@2x.jpg';
import lubosVolkov from './images/lubosVolkov.jpg';
import lubosVolkov2x from './images/lubosVolkov@2x.jpg';
import jacobCass2x from './images/jacobCass@2x.jpg';
import jacobCass from './images/jacobCass.jpg';
import markCherry from './images/markCherry.jpg';
import markCherry2x from './images/markCherry@2x.jpg';
import AugustAgency from './images/AugustAgency.jpg';
import AugustAgency2x from './images/AugustAgency@2x.jpg';
import ArtyomTarasov from './images/ArtyomTarasov.jpg';
import ArtyomTarasov2x from './images/ArtyomTarasov@2x.jpg';
import TrustpilotWidget from "../Trustpilot/TrustpilotWidget";

export default class CustomerTestimonials extends Component {
    items = [
        {
            text:
                'This app looks really promising. It gives you the ability to create nice marketing images without using Photoshop.',
            name: 'Lubos Volkov',
            job: 'Lead designer at Toptal',
            img: lubosVolkov,
            img2x: lubosVolkov2x
        },
        {
            text:
                'I love using this app  and I wish I had my hands on it much earlier in my career, it really is super quick & simple to use!',
            name: 'Jacob Cass',
            job: 'Founder of JUST™ Creative',
            img: jacobCass,
            img2x: jacobCass2x
        },
        {
            text:
                'Saves us a lot of time creating awesome mockups to use to demonstrate new sites to clients.',
            name: 'Mark Cherry',
            job: 'Founder of Green Stripe Media',
            img: markCherry,
            img2x: markCherry2x
        },

        {
            text:
                'I just bought it and I love it. Easily the best tool for mockups I have encountered ever.',
            name: 'Mo Mosby',
            job: 'Freelance Graphic Designer',
            img: MoMosby,
            img2x: MoMosby2x
        },
        {
            text:
                'Smartmockups is the best mockup tool for any studio or individual to produce high-quality mockups in no time.',
            name: 'August Agency',
            job: 'London UK',
            img: AugustAgency,
            img2x: AugustAgency2x
        },

        {
            text: "This is awesome! We're using tons of mockups, so this is really helpful.",
            name: 'Artyom Tarasov',
            job: 'Art Director at Great Simple',
            img: ArtyomTarasov,
            img2x: ArtyomTarasov2x
        }
    ];

    getColStyle() {
        const styles = [];
        if (this.props.design === 'inverse') {
            styles.push(style.inverse);
        }
        if (this.props.layout === 'single') {
            styles.push(style.single);
        }

        return styles.join(' ');
    }

    renderItems(items) {
        return items.map((item, index) => {
            return (
                <div
                    className={`${style.testimonialCol} ${this.getColStyle(index)}`}
                    key={item.name}
                >
                    <div className={`${style.testimonialBox} ${style[this.props.design]}`}>
                        <div className={style.testimonialTitle}>“{item.text}”</div>
                    </div>
                    <div className={style.testimonialAvatar}>
                        <img
                            className={style.testimonialAvatarImg}
                            src={item.img}
                            srcSet={`${item.img2x} 2x`}
                        />

                        <div className={style.testimonialAvatarPerson}>
                            <div
                                className={`${style.testimonialAvatarName} ${
                                    style[this.props.design]
                                }`}
                            >
                                {item.name}
                            </div>
                            <div
                                className={`${style.testimonialAvatarPosition} ${
                                    style[this.props.design]
                                }`}
                            >
                                {item.job}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    render() {
        let item;
        let items;
        switch (this.props.layout) {
            case 'single':
                item = this.items[Math.floor(Math.random() * this.items.length)];
                return (
                    <div className={`${style.customerTestimonials} ${style.single}`}>
                        {this.renderItems([item])}
                    </div>
                );

            case 'centered':
                item = this.items[Math.floor(Math.random() * this.items.length)];
                return (
                    <div className={`${style.customerTestimonials} ${style.single}`}>
                        {this.renderItems([item])}
                    </div>
                );

            default:
                items = this.items.slice(0, 3);
                return (
                    <div>
                        <div className={style.customerTestimonials}>
                            {this.renderItems(items)}
                        </div>
                        <div className={style.trustpilot}>
                            <TrustpilotWidget
                                type="horizontal"
                            />
                        </div>
                    </div>
                );
        }
    }
}

CustomerTestimonials.propTypes = {
    layout: PropTypes.string,
    design: PropTypes.oneOf(['default', 'inverse'])
};
