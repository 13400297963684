import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import Logo from './Logo';
import styles from './Navbar.module.scss';
import NoUserLogin from './NoUserLogin';
import UserMenu from '../../User/UserMenu/UserMenu';
import Nav from './Nav';
import { injectIntl } from 'react-intl';
import Loader from '../../Base/Loader/Loader';
import { isServer } from '../../../utils/envDetection';

const Navbar = (props) => {
    const [isInTwoRows, setIsInTwoRows] = useState(false);
    const left = useRef();
    const right = useRef();
    const center = useRef();

    useEffect(() => {
        setNavbarType();
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    useEffect(() => {
        setNavbarType();
    }, [props.locale, props.user]);

    function setNavbarType() {
        if (!left.current || !right.current || !center.current) return;

        const remainingWidth =
            document.body.clientWidth -
            left.current.getBoundingClientRect().width -
            right.current.getBoundingClientRect().width;

        const checkIsInTwoRows = remainingWidth < center.current.getBoundingClientRect().width;

        if (checkIsInTwoRows) {
            setIsInTwoRows(checkIsInTwoRows);
        }
    }

    const handleWindowResize = () => {
        setNavbarType();
    };

    const {
        user,
        isUserWithoutAccount,
        menuItems,
        onLogoutClick,
        onUpgradeClick,
        onUpgradeTrialClick,
        initialFederatedSignIn
    } = props;

    function renderBar() {
        if (isServer() || !props.isUserLoaded) {
            return null;
        }
        // loading block
        if (initialFederatedSignIn) {
            return <Loader design="grey" size="small" />;
        }
        // usermenu block
        if (user !== null) {
            return (
                <UserMenu
                    user={user}
                    onLogoutClick={onLogoutClick}
                    onUpgradeClick={onUpgradeClick}
                    onUpgradeTrialClick={onUpgradeTrialClick}
                />
            );
        }
        // guest block
        return <NoUserLogin />;
    }

    return (
        <div
            className={`${styles.navbarWrapper} ${isInTwoRows !== false ? styles.navbarWrapperSoonerWrap : null}  `}
        >
            <nav aria-labelledby="primary-navigation" className={styles.navbar}>
                <div className={styles.left} ref={(c) => (left.current = c)}>
                    <Link to="/">
                        <Logo />
                    </Link>
                </div>

                <div className={styles.center} ref={(c) => (center.current = c)}>
                    <Nav menuItems={menuItems} isUserWithoutAccount={isUserWithoutAccount}/>
                </div>
                <div className={styles.right} ref={(c) => (right.current = c)}>
                    <div className={styles.usermenuWrapper}>{renderBar()}</div>
                </div>
            </nav>
        </div>
    );
};

Navbar.propTypes = {
    user: PropTypes.object,
    isUserLoaded: PropTypes.bool,
    isUserWithoutAccount: PropTypes.bool,
    menuItems: PropTypes.array,
    onLogoutClick: PropTypes.func,
    onUpgradeClick: PropTypes.func,
    onUpgradeTrialClick: PropTypes.func,
    initialFederatedSignIn: PropTypes.bool,
};

export default injectIntl(Navbar);
