import React, {useEffect, useReducer, useRef} from 'react';
import Dropdown, { DropdownContent, DropdownTrigger } from '../../Base/Dropdown/Dropdown';
import NotificationIcon from './NotificationIcon.svg';
import NotificationIconUnread from './NotificationIconUnread.svg';
import styles from './NotificationCenter.module.scss';
import { FormattedMessage } from 'react-intl';
import { brazeCachedCards, brazeRefreshCards, brazeToggleCards } from "../../../utils/braze";

const NotificationCenter = () => {

    const initialState = {
        isActive: false,
        isUnread: false,
        unreadCount: null
    };

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    );

    const unread = brazeCachedCards();
    const notificationsElement = useRef(null);

    useEffect(() => {
        brazeRefreshCards();
    },[]);

    useEffect(() => {
        if (unread > 0) {
            setState({
                isActive: true,
                isUnread: true,
                unreadCount: unread
            })
            brazeToggleCards(notificationsElement.current);
        }
    }, [unread]);

    const handleDropdownShow = () => {
        setState({
            isActive: true
        })
        brazeToggleCards(notificationsElement.current);
    };

    const handleDropdownHide = () => {
        setState({
            isActive: false,
            isUnread: false
        })
        brazeToggleCards(notificationsElement.current);
    };

    const renderUnreadAlert = () => {
        if (unread === 0 && state.isUnread === false) return <img src={NotificationIcon} alt="" />;

        return (
            <span>
                <img src={NotificationIconUnread} alt="" /><span className={styles.badge}>{state.unreadCount}</span>
            </span>
        );
    }

    return (
        <div className={styles.notificationCenter}>
            <Dropdown onShow={handleDropdownShow} onHide={handleDropdownHide} active={state.isActive}>
                <DropdownTrigger>
                        <span size="small" design="text" className={styles.notificationCenterBtn}>
                            {renderUnreadAlert()}
                            <span className={styles.label}>
                                <FormattedMessage defaultMessage="Notifications" />
                            </span>
                        </span>
                </DropdownTrigger>
                <DropdownContent
                    className={styles.notificationCenterDropdownContent}
                    position="right"
                >
                    <div className={styles.notificationCenterInner}>
                        <div className={styles.notificationCenterHeader}>
                            <h4>
                                <FormattedMessage defaultMessage="Latest updates" />
                            </h4>{' '}
                        </div>

                        <div className={styles.notificationCenterScroll}>
                            <div ref={notificationsElement} />
                        </div>
                    </div>
                </DropdownContent>
            </Dropdown>
        </div>
    );
}

export default NotificationCenter;
