import Script from "next/script";
import React from "react";

const TrustpilotScript = () => {
    return (
        <Script
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        />
    );
}

export default TrustpilotScript;
