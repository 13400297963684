import React from 'react';
import styles from './InstagramFeed.module.scss';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Headline from '../../Layout/Headline/Headline';
import InstagramFeedItem from './InstagramFeedItem';

const InstagramFeed = (props) => {
    const renderItems = () => {
        if (!props.instagram) return null;
        const sizes = `
        240px,
        (max-width: 768px)  calc(46vw - 2*4px)`;

        return props.instagram.map((item) => {
            return <InstagramFeedItem key={item.id} item={item} sizes={sizes} />;
        });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <div>
                    <Headline
                        title="Instagram"
                        subtitle={
                            <FormattedMessage
                                defaultMessage="Share your mockups on {newline}Instagram and get featured"
                                values={{ newline: <br /> }}
                            />
                        }
                    />
                </div>

                <div className={styles.description}>
                    <FormattedMessage
                        defaultMessage={`Share your projects with <a1> #madewithsmartmockups</a1> and get repost featured on <a2>our Instagram profile</a2>`}
                        values={{
                            a1: (msg) => (
                                <a
                                    href="https://www.instagram.com/explore/tags/madewithsmartmockups/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {msg}
                                </a>
                            ),
                            a2: (msg) => (
                                <a
                                    href="https://www.instagram.com/smartmockups/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {msg}
                                </a>
                            )
                        }}
                    />
                </div>
            </div>
            <div className={styles.grid}>{renderItems()}</div>
        </div>
    );
};

export default InstagramFeed;

InstagramFeed.propTypes = {
    instagram: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string,
            id: PropTypes.string
        })
    )
};
