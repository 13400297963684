import Login from '../Common/Login';
import styles from './LoginPage.module.scss';
import { FormattedMessage } from 'react-intl';
import Link from '../../components/Base/LinkLocalised/LinkLocalised';
import React, { useEffect } from 'react';
import { isClient } from '../../utils/envDetection';
import Router from 'next/router';
import {
    DEFAULT_LOCALE,
    pushBrowserHistoryLocalised,
    removePathnameFromLocale
} from '../../utils/languageHelpers';
import { trackEvent } from '../../utils/trackingParty';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { setPremiumModal } from '../../actions/windowActions';
import PropTypes from 'prop-types';

const LoginContainer = (props) => {
    const dispatch = useDispatch();
    const previousLocation = useSelector((state) => state.window.previousLocation);
    const auth = useSelector((state) => state.auth);

    function getPrefillEmail() {
        // prefill email via query param
        if (isClient() && Router.query && Router.query.email) {
            return Router.query.email;
        }
        return null;
    }

    useEffect(() => {
        if (auth.authenticated && !auth.signInCallback) {
            pushBrowserHistoryLocalised('/mockups');
        }
    }, [auth]);

    const handleSuccessLogin = (user) => {
        // make sure we're not redirecting to password reset or signup page after login
        let redirectLocation = removePathnameFromLocale(previousLocation);
        if (
            redirectLocation === '/' ||
            redirectLocation.indexOf('password') > -1 ||
            redirectLocation.indexOf('signup') > -1
        ) {
            redirectLocation = '/mockups';
        }

        if (user.isTrialEndModal) {
            trackEvent('Trial end modal show');
            dispatch(setPremiumModal('trial-ended', 'Trial end modal'));
        }

        if (user.isSingleAppUser) {
            trackEvent('App user login');
            dispatch(setPremiumModal('app-user'));
        }

        ReactGA.event({
            category: 'User',
            action: 'Login'
        });

        Router.push(redirectLocation, redirectLocation, { locale: DEFAULT_LOCALE });
    };

    return (
        <div className={!props.isCompact ? styles.loginCenter : null}>
            <Login
                onLoginSuccess={handleSuccessLogin}
                isHeadline={!props.isCompact}
                prefillEmail={getPrefillEmail()}
            />
            <div className={styles.bottomWrapper}>
                <Link
                    className={styles.loginPasswordResetBtn}
                    to="/password-reset"
                    tabIndex={0}
                >
                    <FormattedMessage
                        defaultMessage="Forgot password?"
                        id="global.forms.forgotLabel"
                    />
                </Link>
                <span className={styles.sep}>&middot;</span>
                <Link
                    to="/signup"
                    className={styles.signupLink}
                    tabIndex={0}
                >
                    <FormattedMessage
                        defaultMessage="Sign up for free"
                        id="global.nav.label.signupLong"
                    />
                </Link>
            </div>
        </div>
    );
};

export default LoginContainer;

LoginContainer.propTypes = {
    isCompact: PropTypes.bool
};
