import React from 'react';
import styles from './InstagramFeed.module.scss';
import Imgix from 'react-imgix';
import { WEB_IMGIX } from '../../../utils/variables';
import PropTypes from 'prop-types';

const InstagramFeedItem = ({ item, sizes }) => {
    const placeholderUrl = `${WEB_IMGIX}/${item.image}?auto=compress&fit=crop&w=300&h=300&q=1&blur=200`;
    return (
        <a
            href={`https://www.instagram.com/p/${item.id}/`}
            target="_blank"
            rel="noreferrer"
            className={styles.item}
            key={item.id}
        >
            <Imgix
                className={`${styles.image} lazyload`}
                src={`${WEB_IMGIX}/${item.image}`}
                sizes={sizes}
                htmlAttributes={{
                    src: placeholderUrl
                }}
                attributeConfig={{
                    src: 'data-src',
                    srcSet: 'data-srcset',
                    loading: 'lazy'
                }}
                imgixParams={{
                    ar: `1:1`,
                    fit: 'crop',
                    auto: 'compress'
                }}
            />
        </a>
    );
};

export default InstagramFeedItem;

InstagramFeedItem.propTypes = {
    item: PropTypes.shape({
        image: PropTypes.string,
        id: PropTypes.string
    }),
    sizes: PropTypes.string
};
